export default {
  IdentityNumber: 'idNumber',
  PhoneNumber: 'telephone',
  Email: 'email',
  BizLicenseEnterprise: 'accountType',
  BankInfoBank: 'accountBank',
  BankInfoBankCardNumber: 'cardNumber',
  BankInfoBankCardOwnerName: 'accountHolder',
  BankInfoBranchBank: 'accountBranch',
  IdentityCardFront: 'IdentityCardFrontPic',
  IdentityCardBack: 'IdentityCardBackPic',
  BizLicensePicture: 'BizLicensePic',
  BankCardPic: 'BankCardPic',
  BankInfoProvince: 'BankInfoProvince',
  BankInfoCity: 'BankInfoCity',
  BankInfoCounty: 'BankInfoCounty'
}
