<template>
  <div class="acquire-status"
       :class="[computedClassName]"
  >
    <div class="table-cell left-content">
      <div class="status">最新状态</div>
      <div class="pt-10 text-color somber time">{{ lastOperateTime | date }}</div>
      <div class="step-cirlce-icon" :class="[computedClassName]"></div>
    </div>
    <div class="table-cell right-content">
      <div class="status-name">{{ responseText }}</div>
      <div class="pt-10 text-color somber desc">{{ responseDesc }}</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import FormModel from '@/model/acquire-intro/upload/form'

export default {
  props: {},
  data () {
    const formModel = new FormModel()
    return {
      formModel,
      formData: formModel.formData,
      nowStep: 0,
      errorMessage: '',
      lastOperateTime: ''
    }
  },
  created () {
    // 监听表单提交事件，并重新拉取数据
    this.$bus.$on('goToProgress', () => {
      this.currentStep()
    })
    this.currentStep()
  },
  methods: {
    currentStep () {
      this.formModel.currentStep().then(res => {
        this.nowStep = res.step
        this.errorMessage = res.errorMess
        this.lastOperateTime = res.lastOperateTime
      })
    },
    /**
       * 审核状态提示
       */
    enumObjProcess (activeOption) {
      const enumObj = {
        1: '上传资料',
        2: '等待开通',
        3: '等待开通',
        4: '开通成功',
        5: '申请被驳回'
      }
      return enumObj[activeOption] || ''
    },
    /**
       * 审核状态提示
       */
    enumDescProcess (activeOption, errorMessage) {
      const enumObj = {
        1: '预计3-7个工作日',
        2: '预计3-7个工作日',
        3: '预计3-7个工作日',
        4: '',
        5: `驳回理由：${errorMessage}`
      }
      return enumObj[activeOption] || ''
    }
  },
  computed: {
    /**
     * 通过审核码计算当前的状态
     */
    computedClassName () {
      if (this.nowStep === 4) { // 开通成功
        return 'primary'
      } else if (this.nowStep === 5) { // 驳回
        return 'danger'
      } else if (this.nowStep === 2 || this.nowStep === 3) { // 等待审核
        return 'brown'
      }
      return ''
    },
    responseText () {
      return this.enumObjProcess(this.nowStep)
    },
    responseDesc () {
      return this.enumDescProcess(this.nowStep, this.errorMessage)
    }
  },
  watch: {}
}
</script>
