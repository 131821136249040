import * as urls from '@/config/net'
import api from '@/tools/api-tools'
import device from '@/tools/device'
import CebDataMap from './cebDataMap'

class FormData {
  constructor () {
    this.formData = {
      IdentityCardFrontPic: null, // 身份证正面
      IdentityCardBackPic: null, // 身份证反面
      BizLicensePic: null, // 营业执照
      BankCardPic: null, // 银行卡正面
      ProtocolIndexPic: null, // 门头照片
      ShopInsidePic: null, // 店铺场景照
      telephone: null, // 手机号码
      email: null, // 电子邮箱
      accountType: { value: '个人' }, // 账户类型
      addressTexts: [], // 开户地
      BankInfoProvince: null, // 开户省份
      BankInfoCity: null, // 开户市
      BankInfoCounty: null, // 开户区、县
      addressIds: [] // 开户地
    }
    this.formSelectOptions = {
      industryH5: [], // 所属行业(格式适配)
      accountType: [{
        label: '个人',
        value: '个人'
      }, {
        label: '企业',
        value: '企业'
      }] // 账户类型
    }
  }

  // 初始化表单基础数据
  initBaseData () {
    // this._loadSelectOptions()
  }

  async globalFromData () {
    function _load () {
      return api.get(`${urls.apiUrl}/merchant/datacollection/ceb`).then(res => {
        const data = res.data.data || []
        return data
      }).catch(e => {
        return []
      })
    }

    Object.assign(this.formData, this._schemaOriData(await _load()))
  }

  // 获取当前进度
  currentStep () {
    return api.get(`${urls.apiUrl}/cebcollection/states`).then(res => {
      const data = res.data.data || {}
      return { step: data.stateType, errorMess: data.lastRemark, lastOperateTime: data.lastOperateTime }
    })
  }

  _industryWithoutParent (industry) {
    if (!industry) return ''
    return industry.split('-')[1] || industry
  }

  postForm () {
    const formData = {
      BizLicensePic: this.formData.BizLicensePic,
      IdentityCardFrontPic: this.formData.IdentityCardFrontPic,
      IdentityCardBackPic: this.formData.IdentityCardBackPic,
      BankCardPic: this.formData.BankCardPic,
      ProtocolIndexPic: this.formData.ProtocolIndexPic,
      ShopInsidePic: this.formData.ShopInsidePic,
      ContactMobile: this.formData.telephone,
      Email: this.formData.email,
      BankAccountType: this.formData.accountType.value,
      BankProvince: this.formData.addressTexts[0],
      BankCity: this.formData.addressTexts[1],
      BankInfoCounty: this.formData.addressTexts[2],
      sourceFrom: device.ios ? 2 : 1
    }
    return api.post(`${urls.apiUrl}/cebcollection/info`, formData).then(res => {
      // 1代表保存成功
      return res.data.data === 1
    }, e => {
      return false
    })
  }

  // 加载已经初始化的Form
  async loadHavePostForm () {
    const res = await api.get(`${urls.apiUrl}/cebcollection/info`)
    const data = res.data.data || {}
    if (Object.keys(data).length) this._initFormData(data)
  }

  _schemaOriAccount (data) {
    // 后端返回格式 {name: "BizLicenseEnterprise", title: "是否是企业用户", value: "False"}
    const options = { label: '', value: data.value === 'False' ? '个人' : data.value }
    return options
  }

  _schemaOriData (data) {
    return data.reduce((res, item) => {
      const haveMap = CebDataMap[item.name]
      if (haveMap) res[haveMap] = item.value
      // 当为账户类型和开户银行时，返回对象
      if (haveMap === 'accountType') {
        res[haveMap] = this._schemaOriAccount(item)
      }
      this.formData.addressTexts = res.BankInfoProvince || res.BankInfoCity ? [res.BankInfoProvince, res.BankInfoCity, res.BankInfoCounty] : []
      return res
    }, Object.create(null))
  }

  _initFormData (data) {
    this.formData.BizLicensePic = data.bizLicensePic
    this.formData.IdentityCardFrontPic = data.identityCardFrontPic || ''
    this.formData.IdentityCardBackPic = data.identityCardBackPic
    this.formData.BankCardPic = data.bankCardPic
    this.formData.ProtocolIndexPic = data.protocolIndexPic
    this.formData.industry = { value: this._industryWithoutParent(data.industry) }
    this.formData.telephone = data.contactMobile
    this.formData.email = data.email
    this.formData.ShopInsidePic = data.shopInsidePic
    this.formData.accountType = { value: data.bankAccountType ? data.bankAccountType : '个人' }
    this.formData.accountHolder = data.bankAccountName
    this.formData.addressTexts = [data.bankProvince, data.bankCity, data.bankInfoCounty]
  }
}

export default FormData
