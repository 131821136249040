<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="扫码收款" v-if="showNavbar" />
    </template>
    <!-- 内容部分 -->
    <div class="steps">
      <acquire-progress-status></acquire-progress-status>
      <div class="cell-title text-color somber">店铺信息</div>
      <van-cell-group>
        <van-cell title="账户类型" :value="formData.accountType.value" />
        <van-cell :title="addressTitle" :value="formData.addressTexts.join('-')" />
        <van-cell title="负责人手机" :value="formData.telephone" />
        <van-cell title="电子邮箱" :value="formData.email" />
      </van-cell-group>
    </div>
    <template v-slot:footer>
      <div style="padding: 0 15px;">
        <van-button type="warning" v-if="activeProcess.stepFour" block @click="goToCodeMaterial">查看付款码
        </van-button>
        <div v-show="!activeProcess.stepFour" class="text-color gray footer-info">www.i200.cn</div>
      </div>
    </template>
    <template v-slot:routerView>
      <router-view></router-view>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Cell, CellGroup, Picker, Button, Popup } from 'vant'
import FormModel from '@/model/acquire-intro/upload/form'
import AcquireProgressStatus from '../modules/acquire-process'

export default {
  data () {
    const formModel = new FormModel()
    return {
      formModel,
      formData: formModel.formData,
      activeProcess: {
        stepOne: false,
        stepTwo: false,
        stepThree: false,
        stepFour: false,
        errorUpload: false
      },
      showProgress: false,
      showNavbar: false
    }
  },
  components: {
    PageView,
    NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Popup.name]: Popup,
    AcquireProgressStatus
  },
  async created () {
    this.formModel.initBaseData()
    await this.formModel.loadHavePostForm() // 加载表单数据
    this.currentStep()
    this.$bus.$on('goToProgress', () => {
      this.currentStep()
      this.formModel.loadHavePostForm() // 加载表单数据
    })
    if (navigator.userAgent.match(/i200SYZJ/i)) this.showNavbar = true
  },
  methods: {
    /**
     * 获取当前的提交状态
     */
    currentStep () {
      this.formModel.currentStep().then(res => {
        this.loadView(res)
      })
    },
    /**
     * 加载的视图
     */
    loadView (resObj) {
      const nowStep = resObj.step
      switch (nowStep) {
        case 1:
          this.activeProcess.stepOne = true
          this.goToUpload()
          break
        case 2:
          this.showProgress = true
          this.activeProcess.stepTwo = true
          break
        case 3:
          this.showProgress = true
          this.activeProcess.stepThree = true
          break
        case 4:
          this.showProgress = true
          this.activeProcess.stepFour = true
          break
        case 5:
          this.showProgress = true
          this.activeProcess.stepTwo = true
          this.activeProcess.errorUpload = true
          this.goToUpload()
          break
      }
    },
    goToUpload () {
      setTimeout(() => {
        this.$router.push({
          path: '/acquire-intro/progress/upload-info'
        }).catch(() => {})
      }, 0)
    },
    goToCodeMaterial () {
      // pv
      this.$store.dispatch('userInfo/pvCount', 107)
      this.$router.push('/get-QrCode')
    }
  },
  computed: {
    addressTitle () {
      return this.formData.accountType.value === '企业' ? '许可证开户地' : '银行卡开户地'
    }
  }
}
</script>
